<template>
  <v-app>
    <v-app-bar
      primary
      fixed
      dark
      :height="$store.getters.appBarHeight"
      >
      <v-btn class="lighten-2" @click="$i18n.locale = $t('common.otherlocale')">{{ $t('common.otherlocale') }}</v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ userIsAuthenticated ? $t('common.open') : $t('login.title') }}</v-toolbar-title>
      <v-toolbar-items>
        <v-app-bar-nav-icon @click="userIsAuthenticated ? $router.push('/app') : $router.push('/login')">
          <v-icon x-large>mdi-login</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>

    <apptitle></apptitle>

    <!-- <v-parallax
      dark
      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
      height="800"
      class="mt-4"
      >
      <v-row
        align="center"
        justify="center"
      >
        <v-col class="text-center" cols="12">
          <h1 class="display-1 font-weight-thin mb-4">{{ $t('app.promo1title') }}</h1>
          <h4 class="subheading">{{ $t('app.promo1text') }}</h4>
        </v-col>
        <v-col class="text-center" cols="12">
          <h1 class="display-1 font-weight-thin mb-4">{{ $t('app.promo2title') }}</h1>
          <h4 class="subheading">{{ $t('app.promo2text') }}</h4>
        </v-col>
      </v-row>
    </v-parallax> -->
    <!-- <div :style="$store.getters.screenStyle" class="overflow-hidden">
      <v-app-bar
        primary
        dark
        :height="$store.getters.appBarHeight"
        >
        <v-btn class="lighten-2" @click="$i18n.locale = $t('common.otherlocale')">{{ $t('common.otherlocale') }}</v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ userIsAuthenticated ? $t('common.open') : $t('login.title') }}</v-toolbar-title>
        <v-toolbar-items>
          <v-app-bar-nav-icon @click="userIsAuthenticated ? $router.push('/app') : $router.push('/login')">
            <v-icon x-large>mdi-login</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar-items>
      </v-app-bar>
      <v-card class="overflow-y-auto" :max-height="$store.getters.contentSize.height">
        <v-card-title class="mt-6">{{ $t('common.welcome') }}</v-card-title>
        <v-card-text>
          <v-app class="mt-4">
            <apptitle></apptitle>
          </v-app>
          <v-app class="mt-4">
            <v-parallax
              dark
              src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
              height="800"
              >
              <v-row
                align="center"
                justify="center"
              >
                <v-col class="text-center" cols="12">
                  <h1 class="display-1 font-weight-thin mb-4">{{ $t('app.promo1title') }}</h1>
                  <h4 class="subheading">{{ $t('app.promo1text') }}</h4>
                </v-col>
                <v-col class="text-center" cols="12">
                  <h1 class="display-1 font-weight-thin mb-4">{{ $t('app.promo2title') }}</h1>
                  <h4 class="subheading">{{ $t('app.promo2text') }}</h4>
                </v-col>
              </v-row>
            </v-parallax>
          </v-app>
          <v-card class="mt-4 mb-12">
            <v-parallax
              dark
              src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
              >
              test
            </v-parallax>
          </v-card>
        </v-card-text>
        <v-card-actions class="mt-6">
          NeoDev6
        </v-card-actions>
      </v-card>
    </div> -->
  </v-app>
</template>

<script>
import apptitle from '@/components/HomePage/AppTitle.vue'

export default {
  components: {
    apptitle
  },
  methods: {
    openLogin () {
      // window.open('https://www.ptool.ca/login/')
      this.$router.push('/login')
    }
  },
  mounted () {
    // // console.log('trace homepage.vue')
  },
  data () {
    return {
    }
  },
  computed: {
    userIsAuthenticated () {
      return this.$store.getters.userIsSignIn
    }
  }
}
</script>
<style scoped>
  #homePageDiv {
    overflow-y: auto;
  }
</style>
